<template>
	<div class="fadeInRight agentAccount ">
		<div class="title-box clearfix">
			<span class="m-title">代理商账号管理</span>
			<el-form :inline="true" :model="query" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="名称">
					<el-input v-model="query.realname" placeholder="名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button" >搜索</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addAgentRecord" icon="el-icon-plus" class="themed-button">新增</el-button>
				</el-form-item>

			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" border  :max-height="maxTableHeight">
				<el-table-column prop="realname" label="名称">
				</el-table-column>
				<el-table-column prop="mobile" label="登录账号">
				</el-table-column>
				<el-table-column prop="registTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="status" label="状态">
					<template slot-scope="scope">
						{{scope.row.status==0?"正常":"停用"}}
					</template>
				</el-table-column>

				<el-table-column label="操作">
					<template slot-scope="scope1">
						<span @click='updateRecord(scope1.row.id)'   v-if="currentRole==0"   class="right_agent">编辑 </span>
						<span @click='updateAdminPass(scope1.row.id)' class="right_agent">修改密码</span>
					</template>
				</el-table-column>

			</el-table>
		</div>
		<div class="page_box">
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog title="新增代理商账号" :visible.sync="dialogFormVisible" :before-close="handleClose">
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="角色：" :label-width="formLabelWidth"  v-if="currentRole!=0" required>
				<el-select v-model="form.roleId"  placeholder="请选择角色"  filterable>
					 <el-option label="请选择角色" value=""></el-option>
                <el-option   v-for="(item,index) in roleList"   :key="index"   :label="item.name"   :value="item.roleId" ></el-option>
               </el-select>
				</el-form-item>
				<el-form-item label="名称：" :label-width="formLabelWidth" prop="realname">
					<el-input v-model="form.realname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机（登录账号）：" :label-width="formLabelWidth" prop="mobile">
					<el-input v-model="form.mobile" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label=" 登录密码：" :label-width="formLabelWidth" prop="password">
					<el-input v-model="form.password" autocomplete="off" type="password"></el-input>
				</el-form-item>
				<el-form-item label=" 状态：" :label-width="formLabelWidth">
					<el-radio-group v-model="form.status">
						<el-radio label="0">启用</el-radio>
						<el-radio label="1">禁用</el-radio>
					</el-radio-group>

				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="baseFormSubmit('ruleForm')">确 定</el-button>
				<el-button type="primary" @click="baseFormReset('ruleForm')">重置</el-button>
				<el-button @click="cancelDialog('ruleForm')">取 消</el-button>
			</div>
		</el-dialog>
		<el-dialog title="修改代理商账号" :visible.sync="dialogFormVisible1" :before-close="handleClose1">
			<el-form :model="form1" :rules="rules1" ref="ruleForm1">
				<el-form-item label="名称：" :label-width="formLabelWidth" prop="realname">
					<el-input v-model="form1.realname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机（登录账号）：" :label-width="formLabelWidth" prop="mobile">
					<el-input v-model="form1.mobile" autocomplete="off"></el-input>
				</el-form-item>

				<el-form-item label="状态:" :label-width="formLabelWidth">
					<el-radio-group v-model="form1.status">
						<el-radio :label="0">启用</el-radio>
						<el-radio :label="1">禁用</el-radio>
					</el-radio-group>

				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editFormSubmit('ruleForm1')">确 定</el-button>
				<el-button type="primary" @click="editFormReset('ruleForm1')">重置</el-button>
				<el-button @click="cancelDialog1('ruleForm1')">取 消</el-button>
			</div>
		</el-dialog>
		<el-dialog title="修改密码" :visible.sync="dialogFormVisible2" :before-close="handleClose2">
			<el-form :model="form2" :rules="rules2" ref="ruleForm2">
				<el-form-item label="密码：" :label-width="formLabelWidth" prop="password">
					<el-input v-model="form2.password" autocomplete="off" type="password"></el-input>
				</el-form-item>
				<el-form-item label="操作密码:" :label-width="formLabelWidth" prop="operationPassword" v-if="currentRole!=0">
					<el-input v-model="form2.operationPassword" autocomplete="off" type="password"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editPassFormSubmit('ruleForm2')">确 定</el-button>
				<el-button type="primary" @click="editPassFormReset('ruleForm2')">重置</el-button>
				<el-button @click="cancelDialog2('ruleForm2')">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import {
		accountManageList,
		saveAddAgentAccount,
		editAgentAccount,
		editAgentAccountSubmit,
		editPassSubmit,
		agentRoleList
	} from "@/api/agentManagement.js"
	import loading from '@/utils/Loading.js'
	import {mapGetters} from 'vuex'
	export default {
		name:'agentAccountManagement',
		mixins: [onresize],
		data() {
			
			var validateName = (rule, value, callback) => {
				if (!value || "" == value.trim()) {
					return callback(new Error("请输入名称！"))
				} else {
					callback()
				}
			}
			var validatePhone = (rule, value, callback) => {
				if (!value || "" == value.trim()) {
					return callback(new Error("请输手机号！"))
				} else {
					callback()
				}
			}
			var validateInterestRate = (rule, value, callback) => {
				if (!value || value.trim() == "") {
					return callback(new Error("请输入登录密码！"))
				} else {
					callback()
				}
			}
			return {
				page: 1,
				pageSize: 15,
				total: 0,
				query: {},
				tabledata: [],
				form: {
					roleId:"",
					realname: "",
					mobile: "",
					password: "",
					status: "0",
				},
				dialogFormVisible: false,
				rules: {
					
					realname: [{
						required: true,
						validator: validateName,
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						validator: validatePhone,
						trigger: 'blur'
					}],
					password: [{
						required: true,
						validator: validateInterestRate,
						trigger: 'blur'
					}, ]
				},
				formLabelWidth: "200px",
				dialogFormVisible1: false,
				form1: {
					realname: "",
					mobile: "",
					status: 0,
				},
				rules1: {
					realname: [{
						required: true,
						validator: validateName,
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						validator: validatePhone,
						trigger: 'blur'
					}],
				},
				currentId: "",
				dialogFormVisible2: false,
				form2: {
					password: "",
					operationPassword:""
				},
				rules2: {
					password: [{
						required: true,
						validator: validateInterestRate,
						trigger: 'blur'
					}, ],
					operationPassword:[{required: true,message: '请输入操作密码',trigger: 'blur'}],
					
				},
				roleList:[],
			}
		},
		created() {
			this.query = this.$route.query
			this.getAccountManageList(this.query)
		},
		 computed:{
        	...mapGetters(
            	['currentRole']
        	),
    	},
		methods: {
			handleClose(done) {
				this.$refs['ruleForm'].clearValidate()
				done()
			},
			handleClose1(done) {
				this.$refs['ruleForm1'].clearValidate()
				done()
			},
			handleClose2(done) {
				this.$refs['ruleForm2'].clearValidate()
				done()
			},
			getAccountManageList(params) {
				accountManageList(params).then(res => {
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
					this.tabledata = res.rows

				})
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.query)
				this.getAccountManageList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.query)
				this.getAccountManageList(params)
			},
			addAgentRecord() {
				this.dialogFormVisible = true
				if(this.currentRole!=0){
                agentRoleList().then(res=>{
					console.log(res.entity)
				 this.roleList = res.entity
			   })
				}
             
			},
			baseFormSubmit(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.form.roleId)
						if(this.form.roleId =="" && this.currentRole!=0 ){
						  this.$alert('请选择角色', '提示', {
								confirmButtonText: '确定',
							});
                          return 
						}
						this.dialogFormVisible = false
						let params = {
							id: "",
							type: "1",
							agentId: this.query.agentId,
						}
						Object.assign(params, this.form)
						console.log(params)
						saveAddAgentAccount(params).then(res => {
							this.getAccountManageList(this.query)
							if (res.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: res.slide_msg_message_key,
									type: 'warning',
								})
							}
							this.$refs[formName].resetFields()
						})
					} else {

						return false;
					}
				});

			},
			baseFormReset(formName) {
				this.$refs[formName].resetFields()
			},
			cancelDialog(formName) {
				this.$refs[formName].resetFields()
				this.dialogFormVisible = false
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.query)
				this.getAccountManageList(params)
			},
			updateRecord(id) {
				this.dialogFormVisible1 = true
				if (id == 1) {
					this.$alert('1号ID角色是默认角色不允许进行修改操作!', "消息提示", {
						confirmButtonText: '确定',
					})
					return
				}
				this.currentId = id
				/* 编辑参数及发送请求 */
				let params = {
					id: id,
					type: "1",
					agentId: this.query.agentId,
				}
				Object.assign(params, this.form1)
				editAgentAccount(params).then(res => {
					console.log(res)
					this.form1.realname = res.entity.realname
					this.form1.mobile = res.entity.mobile
					this.form1.status = res.entity.status
				})

			},
			editFormSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.dialogFormVisible1 = false
						let params = {
							id: this.currentId,
							type: "1",
							agentId: this.query.agentId,
						}
						Object.assign(params, this.form1)
						editAgentAccountSubmit(params).then(res => {
							this.getAccountManageList(this.query)
							if (res.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: res.slide_msg_message_key,
									type: 'warning',
								})
							}
						})
					} else {
						return false
					}
				});
			},
			editFormReset(formName) {
				let params = {
					id: this.currentId,
					type: "1",
					agentId: this.query.agentId,
				}
				Object.assign(params, this.form1)
				editAgentAccount(params).then(res => {
					console.log(res)
					this.form1.realname = res.entity.realname
					this.form1.mobile = res.entity.mobile
					this.form1.status = res.entity.status
				})
				this.$refs[formName].resetFields()
			},
			cancelDialog1(formName) {
				this.$refs[formName].resetFields()
				this.dialogFormVisible1 = false
			},
			updateAdminPass(id) {
				this.dialogFormVisible2 = true
				this.currentId = id
			},
			editPassFormSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.dialogFormVisible2 = false
						let params = {
							id: this.currentId,
							password: this.form2.password,
							operationPassword:this.form2.operationPassword
						}
						loading.show()
						editPassSubmit(params).then(responseResult => {
							this.form2.password = ""
							this.form2.operationPassword =""
							if (responseResult.slide_msg_key == 1) {
								this.$notify({
									title: '提示',
									message: responseResult.slide_msg_message_key,
								});
							}
							if (responseResult.slide_msg_key == 0) {
								this.$alert(responseResult.slide_msg_message_key, '消息提示', {
									confirmButtonText: '确定',
									callback: action => {
										/* 窗口关闭后的回调*/
									}
								});
							}
							this.getAccountManageList(this.query)
						})
					} else {
						return false;
					}
				});
			},
			cancelDialog2(formName) {
				this.$refs[formName].resetFields()
				this.dialogFormVisible2 = false
			},
			editPassFormReset(formName) {
				this.$refs[formName].resetFields()
			},
		},
	}
</script>

<style>
	/* .el-input{width: 310px;} */
	.agentAccount .el-form-item__label {vertical-align: top;}
</style>
